import { mapContentfulImageAsset } from '@utils/contentful/contentfulImage'
import { Entry } from 'contentful'
import { IProductCategory } from 'types/contentful/productCategory'
import { TRecommendedProduct } from 'types/crossSell'

export const defaultYouAlsoMayLike = [
  {
    crossSellProductImage: {
      imageUrl: '/images/product/slider/noissue-Tape.png',
      imageTitle: 'noissue Tape',
    },
    crossSellProductName: 'noissue Tape',
    linkUrl: '/custom-packaging/custom-packing-tape/',
  },
  {
    crossSellProductImage: {
      imageUrl: '/images/product/slider/noissue-Tissue2.png',
      imageTitle: 'noissue Tissue',
    },
    crossSellProductName: 'noissue Tissue',
    linkUrl: '/custom-packaging/custom-printed-tissue-paper/',
  },
  {
    crossSellProductImage: {
      imageUrl: '/images/product/slider/noissue-Stickers.png',
      imageTitle: 'noissue Stickers',
    },
    crossSellProductName: 'noissue Stickers',
    linkUrl: '/custom-packaging/custom-printed-stickers/',
  },
  {
    crossSellProductImage: {
      imageUrl: '/images/product/slider/noissue-Stamps.png',
      imageTitle: 'noissue Stamps',
    },
    crossSellProductName: 'noissue Stamps',
    linkUrl: '/custom-packaging/custom-stamps/',
  },
  {
    crossSellProductImage: {
      imageUrl: '/images/product/slider/noissue-Cards.png',
      imageTitle: 'noissue Cards',
    },
    crossSellProductName: 'noissue Cards',
    linkUrl: '/custom-packaging/custom-cards/',
  },
  {
    crossSellProductImage: {
      imageUrl: '/images/product/slider/noissue-Mailers.png',
      imageTitle: 'noissue Compostable Mailers',
    },
    crossSellProductName: 'noissue Compostable Mailers',
    linkUrl: '/custom-packaging/wholesale-custom-compostable-mailers/',
  },
] as TRecommendedProduct[]

export function mapProductCategoryToRecommendedProductType(
  productCategory: Entry<IProductCategory>,
  allProductSlugs: string[]
): TRecommendedProduct {
  const productSlug = allProductSlugs?.find((productSlug) =>
    productSlug.includes(`/${productCategory?.fields?.urlSlug}/`)
  )

  return {
    crossSellProductName:
      productCategory?.fields?.crossSellProductName ||
      productCategory?.fields?.productName,
    crossSellProductImage: mapContentfulImageAsset(
      productCategory?.fields?.crossSellImage ||
        productCategory?.fields?.productHeroImages?.[0]
    ),
    linkUrl: `${productSlug}`,
    productSlug: productCategory?.fields?.productSlug,
    productType: productCategory?.fields?.productType,
  }
}
