import { IconButton } from '@noissue-ui-kit/oldBranding/iconButton'
import Slider, { CustomArrowProps, Settings } from 'react-slick'
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

function PrevButton({ onClick }: CustomArrowProps) {
  return onClick ? (
    <IconButton
      onClick={onClick}
      size="s"
      className="absolute -left-[50px] top-1/2 text-core-purple -translate-y-[50%]"
    >
      <HiChevronLeft />
    </IconButton>
  ) : null
}

function NextButton({ onClick }: CustomArrowProps) {
  return onClick ? (
    <IconButton
      onClick={onClick}
      size="s"
      className="absolute -right-[50px] top-1/2 text-core-purple -translate-y-[50%]"
    >
      <HiChevronRight />
    </IconButton>
  ) : null
}

export function CustomSlider(props: Settings) {
  const sliderSettings = {
    ...props,
    nextArrow: <NextButton />,
    prevArrow: <PrevButton />,
  }

  return <Slider {...sliderSettings} className="pb-0" />
}
