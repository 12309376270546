import React from 'react'
import { CustomSlider } from '@components/common/CustomSlider'
import classNames from 'classnames'
import { ProductSlide } from './ProductSlide'
import { TRecommendedProduct } from 'types/crossSell'
import { defaultYouAlsoMayLike } from './ProductSlider.utils'

const sliderSettings = {
  dots: false,
  arrows: true,
  infinite: false,
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        arrows: false,
        slidesToShow: 2.5,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        slidesToShow: 1.2,
        slidesToScroll: 1,
      },
    },
  ],
}

interface IProductSlider {
  className?: string
  title?: string
  data?: TRecommendedProduct[]
}

/**
 * Product Slider
 * @param title Slider Title
 * @param data Slider Data Object
 * @returns
 */
const ProductSlider = ({
  title,
  className,
  data = defaultYouAlsoMayLike,
}: IProductSlider) => {
  return (
    <section
      className={classNames('w-full text-center bg-core-white', className)}
    >
      <div className="max-w-screen-xl px-4 m-auto lg:px-28">
        {title && (
          <h4 className="font-bold text-core-purple text-[42px] mb-20 capitalize">
            {title}
          </h4>
        )}
        <CustomSlider {...sliderSettings}>
          {data?.map((item, index: number) => (
            <ProductSlide
              key={`${index}-visibility-detector-productSlider`}
              index={index}
              item={item}
            />
          ))}
        </CustomSlider>
      </div>
    </section>
  )
}

export default ProductSlider
