import { useWindowSize } from '@utils/responsiveness/useWindowSize'
import debounce from 'lodash.debounce'
import { useEffect, useLayoutEffect, useState } from 'react'
import { TouchSurface } from './TouchSurface'
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi'

const checkLengthAndPrepopulate = (
  arrayToCheck: any[],
  neededLength: number
) => {
  if (arrayToCheck?.length <= neededLength) {
    return checkLengthAndPrepopulate(
      [...arrayToCheck, ...arrayToCheck],
      neededLength
    )
  }
  return arrayToCheck
}

type TSliderDirections = 'RIGHT' | 'LEFT'

const MINIMAL_ARRAY_LENGTH = 8
const START_INDEX = 2
const ITEMS_NEEDED_AHEAD = 4
const ITEMS_NEEDED_BEHIND = 1
const ITEMS_WIDTH_PX = {
  LG: 428,
  SM: 296,
}
const MOVE_DIRECTIONS: {
  RIGHT: TSliderDirections
  LEFT: TSliderDirections
} = {
  RIGHT: 'RIGHT',
  LEFT: 'LEFT',
}

export function ConfigurableListInfinitySlider({ items }: { items: any[] }) {
  const initialSliderItems = checkLengthAndPrepopulate(
    items,
    MINIMAL_ARRAY_LENGTH
  )
  const { width, screenWidth, bodyWidth } = useWindowSize()
  const sliderWidth = bodyWidth || screenWidth || width
  const [slideItemWidth, setSlideItemWidth] = useState<number>(0)
  const [sideScreenOffest, setSideScreenOffset] = useState<number>(0)
  const [direction, setDirection] = useState<TSliderDirections>()
  const [currentIndex, setCurrentIndex] = useState<number>(START_INDEX)
  const [needsTransiton, setNeedTransition] = useState<boolean>(true)
  const [itemsToDisplay, setItemsToDisplay] =
    useState<any[]>(initialSliderItems)
  const [translateValue, setTranslateValue] = useState<number>(
    currentIndex * slideItemWidth
  )
  const [sliderStyle, setSliderStyle] = useState<{
    transform: string
    transition?: string
  }>({
    transform: `translateX(${translateValue}px)`,
    transition: 'transform 0.3s ease-in-out',
  })

  const addSlideAhead = () => {
    const reshuffledItems = [
      ...itemsToDisplay,
      ...itemsToDisplay.slice(0, 1),
    ].slice(-itemsToDisplay.length)
    setNeedTransition(false)
    setItemsToDisplay(reshuffledItems)
    setCurrentIndex(currentIndex - 1)
  }

  const addSlideBehind = () => {
    const reshuffledItems = [
      ...itemsToDisplay.slice(-1),
      ...itemsToDisplay,
    ].slice(0, itemsToDisplay.length)
    setNeedTransition(false)
    setItemsToDisplay(reshuffledItems)
    setCurrentIndex(currentIndex + 1)
  }

  const handleSliderTranslateEnd = () => {
    switch (direction) {
      case MOVE_DIRECTIONS.RIGHT:
        addSlideAhead()
        break
      case MOVE_DIRECTIONS.LEFT:
        addSlideBehind()
        break
      default:
        break
    }
  }

  const moveRight = debounce(() => {
    const newCurrentIndex = currentIndex + 1
    if (newCurrentIndex > itemsToDisplay.length - ITEMS_NEEDED_AHEAD) {
      return
    }
    setCurrentIndex(newCurrentIndex)
    setDirection(MOVE_DIRECTIONS.RIGHT)
    setNeedTransition(true)
  }, 20)

  const moveLeft = debounce(() => {
    const newCurrentIndex = currentIndex - 1
    if (newCurrentIndex < ITEMS_NEEDED_BEHIND) {
      return
    }
    setCurrentIndex(newCurrentIndex)
    setDirection(MOVE_DIRECTIONS.LEFT)
    setNeedTransition(true)
  }, 20)

  useLayoutEffect(() => {
    setTranslateValue(-currentIndex * slideItemWidth + sideScreenOffest)
  }, [currentIndex, slideItemWidth, sideScreenOffest])

  useLayoutEffect(() => {
    if (needsTransiton) {
      setSliderStyle({
        transform: `translateX(${translateValue}px)`,
        transition: 'transform 0.2s ease-in-out',
      })
    } else {
      setSliderStyle({
        transform: `translateX(${translateValue}px)`,
      })
    }
  }, [needsTransiton, translateValue])

  useEffect(() => {
    if (sliderWidth < 768) {
      setSlideItemWidth(ITEMS_WIDTH_PX.SM)
    } else {
      setSlideItemWidth(ITEMS_WIDTH_PX.LG)
    }
    const placeLeftForItemParts = sliderWidth % slideItemWidth
    setSideScreenOffset(placeLeftForItemParts / 2)
  }, [sliderWidth, slideItemWidth])

  return (
    <div className="relative w-full mb-24">
      <div
        className="flex"
        style={sliderStyle}
        onTransitionEnd={handleSliderTranslateEnd}
      >
        {itemsToDisplay.map((item, index) => (
          <div
            className="sm:h-[280px] h-[192px] w-[288px] sm:w-[420px] min-w-[288px] sm:min-w-[420px] rounded-[20px] bg-core-purple mx-[4px] overflow-hidden"
            key={item.id + '_' + item.text + '_' + index}
          >
            <img
              src={item.imageUrl}
              alt="image"
              className="object-cover w-full h-full"
            />
          </div>
        ))}
      </div>

      <TouchSurface moveForward={moveRight} moveBack={moveLeft} />

      <button
        className={`bg-core-white absolute sm:p-[8px] p-[6px] text-xl my-2 border sm:w-16 sm:h-16 w-[32px] h-[32px] rounded-full flex justify-center items-center top-1/2 -translate-y-1/2 left-[45px] sm:left-[75px] -translate-x-1/2 shadow-sm`}
        onClick={moveLeft}
      >
        <HiChevronLeft className="w-[18px] h-[18px]" />
      </button>

      <button
        className={`bg-core-white absolute sm:p-[8px] p-[6px] text-xl my-2 border sm:w-16 sm:h-16 w-[32px] h-[32px] rounded-full flex justify-center items-center top-1/2 -translate-y-1/2 right-[45px] sm:right-[75px] translate-x-1/2 shadow-sm`}
        onClick={moveRight}
      >
        <HiChevronRight className="w-[18px] h-[18px]" />
      </button>
    </div>
  )
}
