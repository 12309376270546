import debounce from 'lodash.debounce'
import { JSXElementConstructor, useState } from 'react'
import { TouchSurface } from './TouchSurface'

interface IMobileConfigurableListSlider {
  sliderItems: any[]
  SliderItemComponent: string | JSXElementConstructor<any>
  keyProp: string
  className?: string
}

export function MobileConfigurableList({
  sliderItems,
  SliderItemComponent,
  keyProp,
  className,
}: IMobileConfigurableListSlider) {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0)

  const moveBack = debounce(() => {
    setActiveSlideIndex(Math.max(0, activeSlideIndex - 1))
  }, 50)

  const moveForward = debounce(() => {
    setActiveSlideIndex(Math.min(sliderItems.length - 1, activeSlideIndex + 1))
  }, 50)

  return (
    <div className={`sm:hidden flex flex-col z-0 ${className}`}>
      <TouchSurface moveForward={moveForward} moveBack={moveBack} />

      <div className="relative z-10 flex w-full mx-auto pointer-events-none flex-nowrap">
        {sliderItems &&
          sliderItems.map((item) => (
            <div
              className={`flex justify-center w-full min-w-full transform transition-all duration-300`}
              style={{
                transform: `translateX(-${100 * activeSlideIndex}%)`,
              }}
              key={item[keyProp]?.toString()}
            >
              <SliderItemComponent {...item} />
            </div>
          ))}
      </div>

      <div className="z-10 flex justify-center w-full mx-auto -mt-10">
        {sliderItems &&
          sliderItems.map((item, index) => (
            <div
              className="py-10 cursor-pointer"
              key={item[keyProp]?.toString() + '_btn'}
              onClick={() => setActiveSlideIndex(index)}
            >
              <button
                className={`w-3 h-3 rounded-full mx-1 transition-colors duration-300 ${
                  index === activeSlideIndex
                    ? 'bg-core-rose'
                    : 'bg-core-purple-20'
                }
                `}
              />
            </div>
          ))}
      </div>
    </div>
  )
}
