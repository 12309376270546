import { ContentfulNextJsImage } from '@components/common/ContentfulImage'
import { IConfigurableFeature } from '../features.types'
import { titleSizeToClass } from '@components/ContentfulPage/contentfulPage.utils'
import { ContentfulMarkdown } from '@utils/contentful/ContentfulMarkdown'
import { mapTextColorToClass } from '@components/ContentfulPage/ConfigurableText/ConfigurableText.utils'

/*
 * This is NestedVerticalFeature in a new branding
 */
export function NestedVerticalFeatureV2({
  title,
  titleColor,
  titleSize,
  description,
  image,
  className,
}: IConfigurableFeature) {
  return (
    <div
      className={`mb-8 sm:mb-0 flex items-left flex-col bg-black-10 rounded-4xl overflow-hidden relative ${className}`}
    >
      {image.imageUrl && (
        <div className="min-h-56 h-auto w-full">
          <ContentfulNextJsImage
            src={image.imageUrl}
            alt={image?.imageTitle}
            objectFit="cover"
            quality={60}
            format="avif"
            height="360"
            width="475"
            placeholder="blur"
          />
        </div>
      )}

      <div className="flex flex-col px-12 pt-16 pb-20">
        {title && (
          <h3
            className={`
                        mb-3 text-3xl font-bold leading-tight text-core-purple text-left
                        ${titleSizeToClass[titleSize] || titleSizeToClass.S}
                        ${
                          mapTextColorToClass[titleColor] ||
                          mapTextColorToClass.purple
                        }
                    `}
          >
            {title}
          </h3>
        )}

        {description && (
          <div className="mb-[6px]">
            <ContentfulMarkdown overrideClass="text-left text-[16px] lg:text-[18px] leading-normal text-core-grey-90">
              {description}
            </ContentfulMarkdown>
          </div>
        )}
      </div>
    </div>
  )
}
