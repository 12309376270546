import { ContentfulNextJsImage } from '@components/common/ContentfulImage'
import { IMinimalisticFeature } from './ConfigurableList'

function FeaturesGrid({ features }: { features: IMinimalisticFeature[] }) {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-20 px-6 sm:px-[120px] font-mori py-6">
      {features &&
        features.map((x) => {
          return (
            <div className="flex justify-start w-full items-center">
              <ContentfulNextJsImage
                src={x.image.imageUrl}
                alt={x.image.imageTitle}
                width={86}
                height={86}
                format="avif"
                objectFit="contain"
                quality={80}
                className="mr-6 flex-shrink-0"
              />

              <div className="flex flex-col">
                <h5 className="text-3xl lg:text-4xl font-semibold text-boulder mb-2">
                  {x.title}
                </h5>
                <p className="text-2xl text-boulder">{x.description}</p>
              </div>
            </div>
          )
        })}
    </div>
  )
}

export { FeaturesGrid }
