import React from 'react'
import { PostOrPage } from '@tryghost/content-api'
import classNames from 'classnames'
import { TheWrapPost } from './TheWrapPost'
import { useDevice } from '@utils/responsiveness/useDevice'
import { KeenSliderWrapper } from '@components/common/KeenSliderWrapper'

/**
 * TheWrap Interface
 */
interface ITheWrap {
  className?: string
  wrapTitle?: string
  posts: Partial<PostOrPage>[]
  trackingIdentifier: string
}

export function TheWrap({
  wrapTitle,
  className,
  posts,
  trackingIdentifier,
}: ITheWrap) {
  const { isMobile } = useDevice()
  const sliderBreakpoints = {
    '(min-width: 480px)': {
      slides: { perView: 1.4, spacing: 16, origin: 'auto' as null },
    },
    '(min-width: 768px)': {
      slides: { perView: 3.2, spacing: 16, origin: 'auto' as null },
    },
    '(min-width: 1024px)': {
      slides: { perView: 3.8, spacing: 16, origin: 'auto' as null },
    },
    '(min-width: 1280px)': {
      slides: { perView: 4.4, spacing: 16, origin: 'auto' as null },
    },
    '(min-width: 1560px)': {
      slides: { perView: 5.2, spacing: 16, origin: 'auto' as null },
    },
  }

  return (
    <section className={classNames('w-full text-center', className)}>
      <div className="px-4 m-auto lg:px-[120px]">
        {wrapTitle && (
          <h3 className="font-bold text-acai text-[32px] mb-20 capitalize">
            {wrapTitle}
          </h3>
        )}
        <KeenSliderWrapper
          initialConfig={{
            perView: 1.25,
            spacing: 16,
            origin: 'auto',
          }}
          breakpoints={sliderBreakpoints}
          isShowArrows={!isMobile}
          hideArrowsWhenDisabled={true}
          className="!overflow-visible"
          trackingIdentifier={trackingIdentifier}
        >
          {posts?.map((post, index) => (
            <div className="keen-slider__slide" key={post.title + '_' + index}>
              <TheWrapPost key={post?.id} post={post} />
            </div>
          ))}
        </KeenSliderWrapper>
      </div>
    </section>
  )
}
