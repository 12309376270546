import { ContentfulNextJsImage } from '@components/common/ContentfulImage'
import EverestVisibilityDetector from '@components/common/EverestVisibilityDetector'
import { trackProductImpression, trackProductClick } from '@utils/gtm'
import { TRecommendedProduct } from 'types/crossSell'

interface IProductSlide {
  index: number
  item: TRecommendedProduct
}

export function ProductSlide({ index, item }: IProductSlide) {
  const trackingData = {
    list: 'You May Also Like',
    buttonText: '',
    item: {
      name: item?.crossSellProductName,
      slug: item.linkUrl,
    },
    position: index,
  }

  return (
    <EverestVisibilityDetector
      onVisible={() => {
        trackProductImpression(trackingData)
      }}
    >
      <div className="mx-4 lg:mx-3 transition-all duration-200 lg:scale-[0.99] hover:scale-100">
        <a
          href={item?.linkUrl}
          onClick={() => {
            trackProductClick(trackingData)
          }}
        >
          <div className="block w-full h-full text-left cursor-pointer bg-core-white group">
            <div className="relative w-full overflow-hidden h-96 rounded-t-3xl">
              {item?.crossSellProductImage?.imageUrl && (
                <ContentfulNextJsImage
                  src={item?.crossSellProductImage?.imageUrl}
                  alt={item?.crossSellProductImage?.imageTitle}
                  layout="fill"
                  backgroundColour="F7F1EC"
                  format="jpg"
                  sizes="(max-width: 480px) 80vw, (max-width: 480px) 35vw, 20vw"
                  quality={60}
                />
              )}
            </div>
            <div className="px-6 py-10 text-center lg:px-10 bg-core-grey-10 rounded-b-3xl">
              <div className="text-2xl font-bold text-core-purple">
                {item.crossSellProductName}
              </div>
            </div>
          </div>
        </a>
      </div>
    </EverestVisibilityDetector>
  )
}
