import { ContentfulNextJsImage } from '@components/common/ContentfulImage'
import { IConfigurableList, IMinimalisticFeature } from './ConfigurableList'
import { Fragment } from 'react'
import classNames from 'classnames'
import { Tab, Transition } from '@headlessui/react'

interface IMultiTabFeaturesList {
  nestedLists: IConfigurableList[]
}

const ListTabContent = ({ features }: { features: IMinimalisticFeature[] }) => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
      {features?.map((feature) => {
        return (
          <div
            className="flex flex-col bg-black-10 items-center justify-center p-16 rounded-4xl font-mori w-full col-span-2 lg:last:[&:nth-child(2n+1)]:col-end-4 lg:last:[&:nth-child(2n-1)]:col-end-[-2]"
            key={feature.title}
          >
            <h5 className="text-3xl font-semibold text-boulder mb-4 text-center">
              {feature.title}
            </h5>
            <p className="text-2xl font-normal text-black-80 mb-16 text-center max-w-screen-xxs">
              {feature.description}
            </p>

            <div className="relative w-full min-h-64">
              <ContentfulNextJsImage
                src={feature?.image?.imageUrl}
                alt={feature?.image?.imageTitle}
                layout="fill"
                width={400}
                objectFit="contain"
                quality={75}
                format="avif"
                placeholder="blur"
              />
            </div>
          </div>
        )
      })}
    </div>
  )
}

function MultiTabFeaturesList({ nestedLists }: IMultiTabFeaturesList) {
  return (
    <section className="flex flex-col mb-32 w-full px-6 sm:px-[120px]">
      <Tab.Group>
        <div className="w-[calc(100%+16px)] translate-x-[8px] sm:translate-x-0 sm:w-auto pr-12 sm:pr-0 overflow-y-auto no-scrollbar mb-10">
          <Tab.List className="flex bg-black-10 mx-auto p-2 rounded-full w-max">
            {nestedLists?.map(({ submenuLabel }) => (
              <Tab as={Fragment} key={submenuLabel}>
                {({ selected }) => (
                  <button
                    className={classNames(
                      'bg-black-10 mx-4 first:ml-0 last:mr-0 text-black-60 text-2xl px-8 py-2 rounded-full pt-3 whitespace-nowrap break-keep select-none',
                      {
                        'bg-white !text-black outline-none !border-none shadow-md':
                          selected,
                      }
                    )}
                  >
                    {submenuLabel}
                  </button>
                )}
              </Tab>
            ))}
          </Tab.List>
        </div>

        <Tab.Panels className="mt-3">
          {nestedLists.map(({ submenuLabel, features }) => (
            <Tab.Panel key={submenuLabel}>
              {({ selected }) => (
                <Transition
                  appear
                  show={selected}
                  enter="transform transition duration-[800ms]"
                  enterFrom="opacity-50 scale-[98%]"
                  enterTo="opacity-100 scale-100"
                  leave="transform duration-[800ms] transition ease-in-out"
                  leaveFrom="opacity-100 scale-100 "
                  leaveTo="opacity-0 scale-[98%] "
                >
                  <div className="rounded-xl bg-white/5 p-3">
                    <ListTabContent
                      features={features as IMinimalisticFeature[]}
                    />
                  </div>
                </Transition>
              )}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </section>
  )
}

export { MultiTabFeaturesList }
