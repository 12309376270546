import { useState } from 'react'

interface ITouchSurface {
  moveForward: () => void
  moveBack: () => void
}

export function TouchSurface({ moveForward, moveBack }: ITouchSurface) {
  const [touchStartPoint, setTouchStartPoint] =
    useState<{ x: number; y: number }>(null)

  const handleClick = (event) => {
    const touchPointX = event?.clientX
    const sliderWidth = event?.target?.clientWidth

    if (touchPointX >= sliderWidth / 2) {
      moveForward()
    } else {
      moveBack()
    }
  }

  const handleTouchStart = (event) => {
    const touchPoint = event?.touches[0]
    setTouchStartPoint({
      x: touchPoint?.clientX,
      y: touchPoint?.clientY,
    })
  }

  const handleTouchMove = (event) => {
    if (!touchStartPoint || !touchStartPoint.x || !touchStartPoint.y) {
      return
    }
    const touchEndPoint = {
      x: event?.touches[0]?.clientX,
      y: event?.touches[0]?.clientY,
    }
    const xDiff = touchStartPoint.x - touchEndPoint.x
    const yDiff = touchStartPoint.y - touchEndPoint.y

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (xDiff > 0) {
        moveForward()
      } else {
        moveBack()
      }
    }
  }
  return (
    <div
      className="absolute top-0 bottom-0 left-0 right-0 block sm:hidden z-5 touch-pan-y"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onClick={handleClick}
    />
  )
}
